import Axios from "axios";
import Cookie from 'js-cookie';
import {
  USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL, USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS, USER_REGISTER_FAIL,URL_SERVER,USER_LOGOUT,  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_RECUPERAR_SUCCESS,
  USER_RECUPERAR_REQUEST,
  USER_RECUPERAR_FAIL,
  USER_FACEBOOK_FAIL,
  USER_FACEBOOK_REQUEST,
  USER_FACEBOOK_SUCCESS,
  USER_FACEBOOK_LOGOUT
} from "../constants/userConstants";
import { USER_UPDATE_FAIL, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS } from "../Types";

const register = (nombre,email,contrasena,documento) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { nombre:nombre,email:email,contrasena:contrasena,documento:documento } });

  try {
    const { data } = await Axios.post(URL_SERVER+"/users/register", { nombre:nombre,email:email, contrasena:contrasena,documento:documento });
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data.data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data.data });
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_REGISTER_FAIL, payload: error.message });
  }
}
function excepciones (ns){
  this.message = ns
}
const signin = (email, contrasena) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email:email, contrasena:contrasena } });

  try {
    const { data } = await Axios.post(URL_SERVER+"/users/signin", { email:email, contrasena:contrasena });
    if(data.status == false){
      throw new excepciones(data.message)
    }
    
     dispatch({ type: USER_SIGNIN_SUCCESS, payload: data.data });

    localStorage.setItem('userInfo', JSON.stringify(data.data));
    
  } catch (error) {
    console.log(error.message)
    dispatch({ type: USER_SIGNIN_FAIL, payload: error.message });
  }

}

const facebook = (email, id_usuario_facebook,nombre) => async (dispatch) => {
  dispatch({ type: USER_FACEBOOK_REQUEST, payload: { nombre:nombre, email:email,
    id_usuario_facebook:id_usuario_facebook } });

  try {
    console.log("datos:",email,id_usuario_facebook,nombre)
    const { data } = await Axios.post(URL_SERVER+"/users/signinFacebook", { nombre:nombre, 
      email:email,id_usuario_facebook:id_usuario_facebook });
      if(data.status == false){
        throw new excepciones(data.message)
      }
     dispatch({ type: USER_FACEBOOK_SUCCESS, payload: data.data });

    localStorage.setItem('userInfo', JSON.stringify(data.data));
    console.log(data)
  } catch (error) {
    dispatch({ type: USER_FACEBOOK_FAIL, payload: error.message });
  }

}

const recuperar = (email) => async (dispatch) => {
  dispatch({ type: USER_RECUPERAR_REQUEST, payload: { email:email } });

  try {
    const { data } = await Axios.post(URL_SERVER+"/recuperar", { email:email });
    
     dispatch({ type: USER_RECUPERAR_SUCCESS, payload: data.data });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_RECUPERAR_FAIL, payload: error.message });
  }

}


const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem('cartItems');
  localStorage.removeItem('facturacion');
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_FACEBOOK_LOGOUT })
  window.location.replace('/');
}




const detailsUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.get(URL_SERVER+`/users/${userId}`);
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
  }
};


const updateUserProfile = (user,id_usuario,nombre, email,contrasena,documento) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  console.log(user)
  try {
    const { data } = await Axios.post(URL_SERVER+`/users/profile`,{ id_usuario:user.userId,nombre:user.nombre,email:user.email, contrasena:user.contrasena,documento:user.documento } ,{
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data.data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data.data });
   
    Cookie.set('userInfo', JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};


export { signin, register,logout,updateUserProfile,detailsUser,recuperar,facebook};